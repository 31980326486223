import React from "react";
import { Jumbotron } from "react-bootstrap";
import YouTube from 'react-youtube-embed';
import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage = () => (
  <Layout>
    <SEO title="robot.haus" />
    <Jumbotron>
      <YouTube id='lbEfV05tJz8' />
    </Jumbotron>
  </Layout>
);

export default IndexPage;
